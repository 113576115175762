let navigated = false
window.addEventListener('hashchange', () => {
  navigated = true
})

export const RedirectToEntity = (basePath, id, data) => `${basePath}/${id}/show`

export const RedirectAfterEdit = (basePath, id, data) => {
  if (navigated)
    return window.history.go(-1)
  return RedirectToEntity(basePath, id, data)
}
