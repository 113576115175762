import { cloneElement } from 'react'

/*
 * Use this component to wrap a child which should received an array of data (e.g: ['one', 'two', 'three'])
 * React-Admin does not support array directly, but only objects. This wrapper turn the raw value 'one' in
 * { label: 'one' }. This way, Label can be used in your child component properly.
 */
export const StringToLabelObject = ({ record, children, ...rest }) => cloneElement(children, {
  record: { label: record },
  ...rest
})
