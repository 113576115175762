import * as React from 'react'
import Card from '@material-ui/core/Card'
import {
  Button, CardActions, CardContent, TextField
} from '@material-ui/core'
import axios from 'axios'

export const ResetPassword = () => {
  const [login, setLogin] = React.useState()
  const [newPassword, setNewPassword] = React.useState()

  const handleSubit = () => {
    const url = window.location.href
    const token = url.slice(url.lastIndexOf('token=') + 'token='.length)

    axios
      .post(`${process.env.REACT_APP_API_URL}/reset-password`, {
        login,
        newPassword,
        token
      })
      .then(() => {
        window.alert('Mot de passe réinitialisé')
      })
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      <Card>
        <CardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <span style={{ margin: '8px' }}>Adresse email de récupération : </span>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              style={{ margin: '8px' }} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <span style={{ margin: '8px' }}>Nouveau mot de passe : </span>
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ margin: '8px' }} />
          </div>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleSubit}>
            ENVOYER
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
