import React from 'react'
import { Admin, Resource } from 'react-admin'
import dotenv from 'dotenv'
import frenchMessages from 'ra-language-french'

// Icons
import Business from '@material-ui/icons/Business'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import { StoreMallDirectory } from '@material-ui/icons'

// Application
import { Route } from 'react-router-dom'
import { HttpProvider } from './providers/http/provider'
import { AuthProvider } from './providers/http/authProvider'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from './configuration/resources'
import domainMessages from './locales/fr.json'

// Pages
import { LoginPage } from './pages/login/LoginPage'
import { OrganizationsList } from './pages/organizations/OrganizationsList'
import { OrganizationCreate } from './pages/organizations/OrganizationCreate'
import { OrganizationEdit } from './pages/organizations/OrganizationEdit'
import { ConfigurationsList } from './pages/configurations/ConfigurationsList'
import { ConfigurationEdit } from './pages/configurations/ConfigurationEdit'
import { AntennaList } from './pages/antennas/AntennaList'
import { AntennaCreate } from './pages/antennas/AntennaCreate'
import { AntennaShow } from './pages/antennas/AntennaShow'
import { AntennaEdit } from './pages/antennas/AntennaEdit'
import { OrganizationShow } from './pages/organizations/OrganizationShow'
import { ForgotPassword } from './pages/customRoutes/ForgotPassword'
import { ResetPassword } from './pages/customRoutes/ResetPassword'

dotenv.config()

const i18nProvider = () => ({ ...domainMessages, ...frenchMessages })

export const App = () => (
  <Admin
    locale="fr"
    i18nProvider={i18nProvider}
    dataProvider={HttpProvider}
    authProvider={AuthProvider}
    loginPage={LoginPage}
    customRoutes={[
      <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
      <Route exact path="/reset-password" component={ResetPassword} noLayout />
    ]}>
    {(permissions) => [
      permissions === 'SUPERADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.ORGANIZATIONS}
          list={OrganizationsList}
          edit={OrganizationEdit}
          show={OrganizationShow}
          create={OrganizationCreate}
          icon={Business} />
      ) : null,
      permissions === 'SUPERADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.CONFIGURATIONS}
          list={ConfigurationsList}
          edit={ConfigurationEdit}
          icon={SettingsApplicationsIcon} />
      ) : null,
      permissions === 'SUPERADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.ANTENNAS}
          list={AntennaList}
          edit={AntennaEdit}
          create={AntennaCreate}
          show={AntennaShow}
          icon={StoreMallDirectory} />
      ) : null
    ]}
  </Admin>
)
