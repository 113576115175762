import React from 'react'
import { Login } from 'react-admin'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'
import { Button, Card, CardActions } from '@material-ui/core'

const bg = require('../../assets/images/background.png')

const styles = {
  container: {
    backgroundPosition: 'center'
  }
}

const CustomLogin = withStyles({
  card: { marginTop: '13rem' }
})(Login)

export const LoginPage = withStyles(styles)(({ classes }) => (
  <>
    <CustomLogin className={classes.container} backgroundImage={bg} />
    <Card>
      <CardActions align-items="center">
        <Button size="small" color="primary" href="#/forgot-password">
          MOT DE PASSE OUBLIÉ
        </Button>
      </CardActions>
    </Card>
  </>
))
