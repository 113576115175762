import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  number,
  email,
  CheckboxGroupInput
} from 'react-admin'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'
import { NAMES, PHONES, POSTAL_CODES } from '../../configuration/validation'
import { RedirectToEntity } from '../../utils/RedirectAfterEdit'

const styles = {
  formInputContainer: {
    width: '100%'
  }
}

export const OrganizationCreate = withStyles(styles)(({ classes, ...props }) => (
  <Create {...props}>
    <SimpleForm className={classes.formInputContainer} redirect={RedirectToEntity}>
      <TextInput source="name" label="Nom" validate={required()} />
      <TextInput source="distantId" label="SIMILO ID" validate={[required(), number()]} />
      <TextInput source="similoPassword" label="Mot de passe SIMILO" />
      <BooleanInput source="active" label="Active" />
      <CheckboxGroupInput
        source="flags"
        label="Propriétés"
        choices={[
          { id: 'IS_SHOWED_IN_AFFILIATION', name: "Affichée dans le parcours initial d'accompagnement / suivi" }
        ]} />
      <hr style={{ width: '100%' }} />
      <p style={{ color: '#555', fontSize: '14px' }}>Valeurs par défaut:</p>
      <TextInput source="city" label="Ville" validate={required()} />
      <TextInput source="address" label="Adresse" />
      <NumberInput source="position[0]" label="Longitude" validate={[required(), number()]} />
      <NumberInput source="position[1]" label="Latitude" validate={[required(), number()]} />
      <TextInput source="email" label="Email" validate={[required(), email()]} />
      <TextInput source="phone" label="Téléphone" validate={[required(), PHONES]} />
      <TextInput source="linkAppointment" label="Lien calendly" />
      <ArrayInput source="postalCodes" label="Codes postaux" className={classes.formInputContainer}>
        <SimpleFormIterator>
          <TextInput label="Code postal" validate={[POSTAL_CODES]} defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="referents" label="Référents" className={classes.formInputContainer}>
        <SimpleFormIterator>
          <TextInput source="lastName" label="Nom du référent" validate={[required(), NAMES]} />
          <TextInput source="firstName" label="Prénom du référent" validate={[required(), NAMES]} />
          <TextInput source="email" label="Email à afficher" validate={[required(), email()]} />
          <TextInput source="phone" label="Téléphone à afficher" validate={[required(), PHONES]} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
))
