export const IDENTIFIERS = {
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_IMPORTS: 'organizations_imports',
  CONFIGURATIONS: 'configurations',
  ANTENNAS: 'antennas'
}

export const resources = {
  [[IDENTIFIERS.ORGANIZATIONS]]: {
    name: IDENTIFIERS.ORGANIZATIONS,
    url: 'organizations'
  },
  [[IDENTIFIERS.ORGANIZATIONS_IMPORTS]]: {
    name: IDENTIFIERS.ORGANIZATIONS_IMPORTS,
    url: 'organizations/import'
  },
  [[IDENTIFIERS.CONFIGURATIONS]]: {
    name: IDENTIFIERS.CONFIGURATIONS,
    url: 'configurations'
  },
  [[IDENTIFIERS.ANTENNAS]]: {
    name: IDENTIFIERS.ANTENNAS,
    url: 'antennas'
  }
}

/**
 * Returns the URL according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceUrl = (name) => {
  if (resources[name])
    return resources[name].url

  return name
}

/**
 * Returns the label according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceLabel = (name) => {
  if (resources[name])
    return resources[name].label

  return name
}
