import React from 'react'
import PropTypes from 'prop-types'
import {
  List, CreateButton, Datagrid, TextField, BooleanField
} from 'react-admin'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'
import ImporterButton from '../../components/ImporterButton'
import { ConditionalBoolean } from '../../utils/ConditionalBoolean'
import { ORGANIZATION_FLAGS } from '../../configuration/constants'

const styles = {
  ListToolbarToolbar: {
    justifyContent: 'flex-end'
  }
}
const Actions = ({ classes, basePath }) => (
  <div className={classes.ListToolbarToolbar}>
    <CreateButton basePath={basePath} />
    <ImporterButton />
  </div>
)

Actions.propTypes = {
  basePath: PropTypes.string.isRequired
}

export const OrganizationsList = withStyles(styles)((props) => (
  <List {...props} actions={<Actions {...props} />} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Nom" />
      <BooleanField source="active" label="Active" />
      <ConditionalBoolean
        condition={(record) => !!record.flags && record.flags.includes(ORGANIZATION_FLAGS.IS_SHOWED_IN_AFFILIATION)}
        label="Affichage ML">
        <BooleanField source="condition" label="Affichage ML" />
      </ConditionalBoolean>
      <TextField source="address" label="Adresse" />
      <TextField source="city" label="Ville" />
      <TextField source="email" label="Email" />
      <TextField source="phone" label="Téléphone" />
    </Datagrid>
  </List>
))
