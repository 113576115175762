import React from 'react'
import {
  List, SimpleList, translate
} from 'react-admin'

const ConfigurationsListComponent = (props) => (
  <List title="Configurations" {...props} pagination={<div />} actions={<div />}>
    <SimpleList
      primaryText={(record) => props.translate(`pages.configurations.records.${record.name}.name`)}
      secondaryText={(record) => (<span><b>{ record.secret ? '[Non visible car protégé]' : ''}</b> { props.translate(`pages.configurations.records.${record.name}.description`) }</span>)} />
  </List>
)

export const ConfigurationsList = translate(ConfigurationsListComponent)
