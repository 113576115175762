import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, submit, isSubmitting } from 'redux-form'
import { refreshView } from 'ra-core'
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  CREATE,
  FileField,
  FileInput,
  withDataProvider
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import SystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { CONTENT_TYPES } from '../providers/http/provider'
import { IDENTIFIERS } from '../configuration/resources'

const FORM_NAME = 'post-quick-create'

class ImporterButton extends Component {
  state = {
    error: false,
    showDialog: false,
    result: null,
    isLoading: false
  }

  handleClick = () => {
    this.setState({ showDialog: true, isLoading: false, result: null })
  }

  handleCloseClick = () => {
    this.clean(this.props)
    this.setState({ showDialog: false, isLoading: false, result: null })
  }

  handleSaveClick = () => {
    const { submit } = this.props

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit(FORM_NAME)
  }

  getMessage({ type, name, reason }) {
    const typeName = type === 'ML' ? 'Mission Locale' : 'Antenne'
    if (!reason)
      return `${typeName} '${name}' importée`
    switch (reason) {
      case 'KNOWN':
        return `${typeName} '${name}' ignorée: déjà connue`
      case 'DUPLICATE':
        return `${typeName} '${name}': importée mais doublon ignoré`
      case 'NO_ORGS':
        return `${typeName} '${name}' ignorée: Mission Locale inconnue`
      default:
    }
  }

  handleSubmitResult = ({ imported, rejected }) => {
    this.clean(this.props)
    this.props.refreshView()

    const messages = rejected
      .map((obj) => this.getMessage(obj))
      .filter((it) => !!it)
      .concat([
        '',
        `Nombres de ML importées: ${imported.filter((it) => it.type === 'ML').length}`,
        `Nombres d'Antennes importées: ${imported.filter((it) => it.type === 'ANTENNA').length}`
      ])
    this.setState({ result: messages })
  }

  handleSubmit = (values) => {
    const {
      fetchStart, fetchEnd, showNotification, dataProvider
    } = this.props

    if (!values.files || !values.files.rawFile)
      return showNotification("Merci d'ajouter un fichier", 'error')
    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart()

    this.setState({ isLoading: true })

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(
      { action: CREATE, resource: IDENTIFIERS.ORGANIZATIONS_IMPORTS, contentType: CONTENT_TYPES.MULTIPART_FORM_DATA },
      { data: { file: values.files.rawFile } }
    )
      .then(({ data }) => this.handleSubmitResult(data))
      .catch((error) => {
        this.setState({ result: [`Une erreur technique est survenue: ${error}`], isLoading: false })
      })
      .finally(() => {
        this.setState({ isLoading: false })
        fetchEnd()
      })
  }

  clean({ change }) {
    change(FORM_NAME, 'files', null)
  }

  render() {
    const { showDialog, isLoading, result } = this.state
    const { isSubmitting } = this.props

    return (
      <>
        <Button color="primary" onClick={this.handleClick} label="Importer">
          <SystemUpdateAlt />
        </Button>
        <Dialog
          fullWidth
          disableBackdropClick
          hideBackdrop
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Importer des Missions Locales">
          <DialogTitle>Importer des Missions Locales</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form={FORM_NAME}
              resource={IDENTIFIERS.ORGANIZATIONS_IMPORTS}
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}>
              {result ? (
                <>
                  {result.length ? (
                    <>
                      <p>
                        <b>Importation terminée:</b>
                      </p>
                      <div>
                        {result.map((it, index) => (
                          <span key={index}>
                            {it}
                            <br />
                          </span>
                        ))}
                      </div>
                    </>
                  ) : (
                    <p>
                      <b>Importation terminée avec succès</b>
                    </p>
                  )}
                </>
              ) : (
                <FileInput source="files" label="Fichier CSV" accept=".csv">
                  <FileField source="src" title="title" />
                </FileInput>
              )}
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <SaveButton
                  saving={isSubmitting}
                  onClick={this.handleSaveClick}
                  label="Importer"
                  icon={<SystemUpdateAlt />} />
                <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                  <IconCancel />
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting('post-quick-create')(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDataProvider(ImporterButton))
