import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, isSubmitting, submit } from 'redux-form'
import { refreshView } from 'ra-core'
import {
  Button,
  fetchEnd,
  fetchStart,
  SaveButton,
  showNotification,
  SimpleForm,
  TextInput,
  withDataProvider
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import CircularProgress from '@material-ui/core/CircularProgress'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { IDENTIFIERS, resources } from '../configuration/resources'
import { dataProvider } from '../helpers/dataProvider'

const FORM_NAME = 'post-similo-password'

class ChangeOrganizationPasswordButton extends Component {
  state = {
    showDialog: false,
    isLoading: false
  }

  handleClick = () => {
    this.setState({ showDialog: true, isLoading: false })
  }

  handleCloseClick = () => {
    this.clean(this.props)
    this.setState({ showDialog: false, isLoading: false })
  }

  handleSaveClick = () => {
    const { submit } = this.props
    submit(FORM_NAME)
  }

  handleSubmit = (values) => {
    const { fetchStart, fetchEnd, showNotification } = this.props
    if (!values.similoPassword)
      return showNotification("Merci d'entrer le nouveau mot de passe", 'error')
    fetchStart()
    this.setState({ isLoading: true })

    // DataProvider directly
    dataProvider
      .post(`/${resources[IDENTIFIERS.ORGANIZATIONS].url}/${this.props.record.id}/similo-password`, {
        similoPassword: values.similoPassword
      })
      .then((response) => {
        if (response.status === 200) {
          showNotification('Mot de passe SIMILO changé', 'info')
          window.location.reload()
        } else
          showNotification('Erreur lors du changement du mot de passe SIMILO', 'error')
      })
      .catch((error) => {
        showNotification(`Erreur technique: ${error.message}`, 'error')
      })
      .finally(() => {
        this.setState({ isLoading: false })
        fetchEnd()
        this.clean(this.props)
        this.setState({ showDialog: false, isLoading: false })
      })
  }

  clean({ change }) {
    change(FORM_NAME, 'similoPassword', null)
  }

  render() {
    const { showDialog, isLoading } = this.state
    const { isSubmitting } = this.props

    return (
      <>
        <Button color="primary" onClick={this.handleClick} label="Mot de passe SIMILO">
          <VpnKeyIcon />
        </Button>
        <Dialog
          fullWidth
          disableBackdropClick
          hideBackdrop
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Changer le mot de passe SIMILO de cette structure">
          <DialogTitle>Changer le mot de passe SIMILO de cette structure</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form={FORM_NAME}
              resource={IDENTIFIERS.ORGANIZATIONS}
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              toolbar={null}>
              <p>
                <b>Attention:</b> Cette action va changer le mot de passe SIMILO de cette structure. Une fois changé, le
                mot de passe ne pourra plus être récupéré.
              </p>
              <TextInput source="similoPassword" label="Nouveau mot de passe SIMILO" fullWidth />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <SaveButton
                  saving={isSubmitting}
                  onClick={this.handleSaveClick}
                  label="Envoyer"
                  icon={<VpnKeyIcon />} />
                <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                  <IconCancel />
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting(FORM_NAME)(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDataProvider(ChangeOrganizationPasswordButton))
