import { regex } from 'react-admin'

const NAME = new RegExp('^(?![- ])[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF- ]+[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]$')
const NAME_MESSAGE = 'Doit commencer et terminer par une lettre et contenir uniquement des lettres, espaces ou tiret'

const PHONE = new RegExp('^[+]{0,1}[0-9 ]+[0-9]+$')
const PHONE_MESSAGE = "Doit terminer par un chiffre et être composé de chiffres et d'espaces."

const POSTAL_CODE = new RegExp('^[0-9]{5}$')
const POSTAL_CODE_MESSAGE = 'Doit contenir exactement 5 chiffres'

export const NAMES = regex(NAME, NAME_MESSAGE)
export const PHONES = regex(PHONE, PHONE_MESSAGE)
export const POSTAL_CODES = regex(POSTAL_CODE, POSTAL_CODE_MESSAGE)
