import axios from 'axios'
import { configuration } from '../configuration/configuration'

const post = (url, data) => axios.post(`${configuration.apiUrl}${url}`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Content-Type': 'application/json'
  }
})

export const dataProvider = { post }
