import * as React from 'react'
import Card from '@material-ui/core/Card'
import {
  Button, CardActions, CardContent, TextField
} from '@material-ui/core'
import axios from 'axios'

export const ForgotPassword = () => {
  const [name, setName] = React.useState()

  const handleSubit = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { login: name }).then(() => {
      window.alert("S'il s'agit bien du mail d'un administrateur, un mail a été envoyé")
    })
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
      <Card>
        <CardContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <span style={{ margin: '8px' }}>Adresse email de récupération : </span>
            <TextField
              margin="normal"
              id="email"
              label="Email"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)} />
          </div>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={handleSubit}>
            ENVOYER
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}
