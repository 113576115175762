import React from 'react'
import PropTypes from 'prop-types'
import {
  List, CreateButton, Datagrid, TextField, ReferenceField, BooleanField
} from 'react-admin'
import { ConditionalBoolean } from '../../utils/ConditionalBoolean'
import { ANTENNAS_FLAGS } from '../../configuration/constants'

const Actions = ({ basePath }) => <CreateButton basePath={basePath} />

Actions.propTypes = {
  basePath: PropTypes.string.isRequired
}

export const AntennaList = (props) => (
  <List {...props} actions={<Actions {...props} />} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Nom" />
      <ConditionalBoolean
        condition={(record) => !!record.flags && record.flags.includes(ANTENNAS_FLAGS.IS_SHOWED_IN_AFFILIATION)}
        label="Affichage ML">
        <BooleanField source="condition" label="Affichage ML" />
      </ConditionalBoolean>
      <ReferenceField source="organizationId" label="Mission Locale" reference="organizations" target="id">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="address" label="Adresse" />
      <TextField source="city" label="Ville" />
      <TextField source="email" label="Email" />
      <TextField source="phone" label="Téléphone" />
      <TextField source="position[0]" label="Longitude" />
      <TextField source="position[1]" label="Latitude" />
      <TextField source="linkAppointment" label="Lien Calendly" />
    </Datagrid>
  </List>
)
