import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  number,
  email,
  CheckboxGroupInput
} from 'react-admin'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'
import { PHONES, POSTAL_CODES } from '../../configuration/validation'
import { RedirectAfterEdit } from '../../utils/RedirectAfterEdit'

const styles = {
  formInputContainer: {
    width: '100%'
  }
}

export const AntennaEdit = withStyles(styles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm className={classes.formInputContainer} redirect={RedirectAfterEdit}>
      <TextInput source="name" label="Nom" validate={required()} />
      <CheckboxGroupInput
        source="flags"
        label="Propriétés"
        choices={[
          { id: 'IS_SHOWED_IN_AFFILIATION', name: "Affichée dans le parcours initial d'accompagnement / suivi" }
        ]} />
      <TextInput source="city" label="Ville" validate={required()} />
      <ReferenceInput
        label="Mission locale"
        validate={[required()]}
        source="organizationId"
        reference="organizations"
        perPage={50}
        filterToQuery={(searchText) => ({ qName: searchText })}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="position[0]" label="Longitude" validate={[required(), number()]} />
      <NumberInput source="position[1]" label="Latitude" validate={[required(), number()]} />
      <TextInput source="address" label="Adresse" />
      <TextInput source="email" label="Email" validate={[email()]} />
      <TextInput source="phone" label="Téléphone" validate={[PHONES]} />
      <ArrayInput source="postalCodes" label="Codes postaux" className={classes.formInputContainer}>
        <SimpleFormIterator>
          <TextInput
            label="Code postal"
            validate={[required(), POSTAL_CODES]}
            format={(v) => (v instanceof Object ? '' : v)} />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="linkAppointment" label="Lien Calendly" />
    </SimpleForm>
  </Edit>
))
