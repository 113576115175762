import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS
} from 'react-admin'
import axios from 'axios'
import { configuration } from '../../configuration/configuration'

const actions = {
  // Called when the user attempts to log in
  [AUTH_LOGIN]: async (params) => {
    const { username, password } = params
    const response = await axios.post(`${configuration.apiAuthUrl}/login`, {
      login: username,
      password,
      rememberMe: true
    })
    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)
  },
  // Called when the user clicks on the logout button
  [AUTH_LOGOUT]: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('authority')
  },
  // Called when the API returns an error
  [AUTH_ERROR]: async () => {
    const response = await axios.post(`${configuration.apiAuthUrl}/refresh`, {
      refreshToken: localStorage.getItem('refreshToken')
    })
    localStorage.setItem('accessToken', response.data.accessToken)
    localStorage.setItem('refreshToken', response.data.refreshToken)
  },
  // Called when the user navigates to a new location
  [AUTH_CHECK]: () => !!localStorage.getItem('accessToken'),
  [AUTH_GET_PERMISSIONS]: () => 'SUPERADMIN'
}

export const AuthProvider = async (type, params) => {
  if (actions[type])
    return actions[type](params)
  throw Error(`Unknown action ${type}`)
}
