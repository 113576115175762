import React from 'react'
import PropTypes from 'prop-types'
import {
  CreateButton,
  TextField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  ChipField,
  ArrayField,
  SingleFieldList,
  Tab
} from 'react-admin'
import { StringToLabelObject } from '../../utils/StringToLabelObject'

const Actions = ({ basePath }) => <CreateButton basePath={basePath} />

Actions.propTypes = {
  basePath: PropTypes.string.isRequired
}

export const AntennaShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Détails">
        <TextField source="name" label="Nom" />
        <ReferenceField source="organizationId" label="Mission Locale" reference="organizations" target="id">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="position[0]" label="Longitude" />
        <TextField source="position[1]" label="Latitude" />
        <TextField source="address" label="Adresse" />
        <TextField source="city" label="Ville" />
        <ArrayField source="postalCodes" label="Code postaux">
          <SingleFieldList>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Téléphone" />
        <TextField source="linkAppointment" label="Lien Calendly" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
