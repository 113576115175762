import axios from 'axios'

export const httpClient = (params = {}) => {
  const options = params
  if (!params.headers)
    options.headers = { Accept: 'application/json' }

  options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return axios(options)
  /* .catch((err) => {
    localStorage.setItem('needsRefresh', err.message.includes('401'))
    throw Error('Refreshing...')
  }) */
}
