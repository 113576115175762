import React from 'react'
import PropTypes from 'prop-types'
import {
  EditButton,
  TextField,
  Show,
  TabbedShowLayout,
  ChipField,
  ArrayField,
  SingleFieldList,
  Tab,
  Datagrid,
  ReferenceManyField,
  ReferenceField,
  BooleanField
} from 'react-admin'
import { CardActions } from '@material-ui/core'
import { StringToLabelObject } from '../../utils/StringToLabelObject'
import ChangeOrganizationPasswordButton from '../../components/ChangeOrganizationPasswordButton'

const cardActionStyle = {
  display: 'inline-block',
  float: 'right'
}
const Actions = ({ data, basePath }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ChangeOrganizationPasswordButton record={data} />
  </CardActions>
)

Actions.propTypes = {
  basePath: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
}

export const OrganizationShow = (props) => (
  <Show {...props} actions={<Actions {...props} />}>
    <TabbedShowLayout>
      <Tab label="Détails">
        <TextField source="name" label="Nom" />
        <BooleanField source="isSimiloPasswordSet" label="Mot de passe SIMILO défini" />
        <TextField source="position[0]" label="Longitude" />
        <TextField source="position[1]" label="Latitude" />
        <TextField source="address" label="Adresse" />
        <TextField source="city" label="Ville" />
        <ArrayField source="postalCodes" label="Code postaux">
          <SingleFieldList>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Téléphone" />
        <TextField source="linkAppointment" label="Lien Calendly" />
      </Tab>
      <Tab label="Référents">
        <ArrayField source="referents" label="">
          <Datagrid>
            <TextField source="lastName" label="Nom" />
            <TextField source="firstName" label="Prénom" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Téléphone" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Antennes">
        <ReferenceManyField reference="antennas" target="organizationId" label="">
          <Datagrid>
            <ReferenceField source="id" label="Nom" reference="antennas" target="id">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="position[0]" label="Longitude" />
            <TextField source="position[1]" label="Latitude" />
            <TextField source="address" label="Adresse" />
            <TextField source="city" label="Ville" />
            <ArrayField source="postalCodes" label="Code postaux">
              <SingleFieldList>
                <StringToLabelObject>
                  <ChipField source="label" />
                </StringToLabelObject>
              </SingleFieldList>
            </ArrayField>
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Téléphone" />
            <TextField source="linkAppointment" label="Lien Calendly" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
