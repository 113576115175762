import React from 'react'
import {
  Edit,
  SimpleForm,
  FunctionField,
  DisabledInput,
  Toolbar,
  ArrayInput,
  SaveButton,
  TextInput,
  NumberInput,
  SimpleFormIterator
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'

const OnlySaveToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const styles = {
  formInputContainer: {
    width: '100%'
  }
}

export const ConfigurationEdit = withStyles(styles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm toolbar={<OnlySaveToolbar />}>
      <DisabledInput label="Nom" source="name" />
      <FunctionField
        addLabel={false}
        className={classes.formInputContainer}
        render={(record) => {
          if (Array.isArray(record.value)) {
            return (
              <ArrayInput record={record} source="value" label="Valeurs">
                <SimpleFormIterator defaultValue="">
                  <TextInput />
                </SimpleFormIterator>
              </ArrayInput>
            )
          }
          if (typeof record.value === 'string' && record.value.length < 250)
            return (<TextInput source="value" label="Valeur" placeholder={record.secret ? '******' : ''} />)
          if (typeof record.value === 'string' && record.value.length > 250)
            return (<RichTextInput style={{ width: '100%' }} source="value" label="Valeur" />)
          if (typeof record.value === 'number')
            return (<NumberInput source="value" label="Valeur"/>)
          return (<div />)
        }} />
    </SimpleForm>
  </Edit>
))
